@import 'src/scss/palette';
@import 'src/scss/functions';

.signup-popup-seo {
    position: fixed;
    left: 0;
    width: 100%;
    padding: snif-grid(3) snif-grid(2);
    background-color: $white;
    border-top: 3px solid $green;
    overflow: overlay;
    z-index: 150;

    @media #{$min-width-mobile} {
        width: 384px;
        bottom: 32px;
        left: 32px;
        padding: snif-grid(3);
        border-top: none;
        border-radius: 10px;
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
    }

    @media #{$max-width-mobile} {
        bottom: 0dvh;

        &.bot-nav-test {
            bottom: calc(0dvh + 64px);
            border-bottom: 1px solid $fog-gray;
        }

        &.focused {
            padding: snif-grid(2);

            &.bot-nav-test {
                max-height: calc(100dvh - 64px);
                border-bottom: 1px solid $fog-gray;
            }
        }
    }

    &__title {
        display: flex;
        justify-content: space-between;
        margin-bottom: snif-grid(2);

        p {
            width: calc(100% - 40px);
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;

            @media #{$min-width-mobile} {
                font-size: 18px;
            }
        }

        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            background-color: $snow-white;
            border-radius: 50%;
            cursor: pointer;

            svg {
                width: 16px;
                height: 16px;
            }
        }
    }

    &__seopopup-after-reg {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index: 175;

        &_bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #000000b3;
        }

        &_content {
            position: relative;
            width: 100%;
            margin: 0 snif-grid(2);
            padding: snif-grid(4) snif-grid(2);
            text-align: center;
            background-color: $white;
            z-index: 200;

            @media #{$min-width-mobile} {
                width: 556px;
                padding: 40px 32px;
            }
        }

        &_close {
            position: absolute;
            bottom: calc(100% + 4px);
            right: 0;
            width: 100px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: snif-grid(1) 0;
            color: $white;
            font-size: 16px;
            line-height: 24px;
            cursor: pointer;

            svg {
                margin-left: snif-grid(1);
            }
        }

        &_coupon {
            margin-top: snif-grid(3);
            padding: snif-grid(1.5);
            font-size: 32px;
            line-height: 40px;
            font-weight: 600;
            background: $green-10-opct;
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%233AA648FF' stroke-width='4' stroke-dasharray='12' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
            border-radius: 4px;
        }
    }

    .mrgn-seopopup {
        margin-top: snif-grid(1.5) !important;
    }
}
