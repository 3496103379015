@import 'src/scss/palette';
@import 'src/scss/variables';
@import 'src/scss/functions';
@import 'src/scss/mixins/typorgaphy';

.review-toast {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: $white;

    .toast-icon {
        margin-right: snif-grid(2);
    }
}

.Toastify {
    .Toastify__toast-container {
        width: 360px;

        .Toastify__toast {
            font-family: 'Work Sans';
            box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
            padding: snif-grid(2);
            border-radius: 8px;

            &--success {
                background-color: $success-500;
            }

            &--error {
                background-color: $red;

                &.dark {
                    background-color: $dark-gray;
                    color: $white;
                }
            }

            &--dark {
                background-color: #4a484b;
                color: $white;

                .Toastify__progress-bar--dark {
                    background-color: $fog-gray;
                }
            }
        }
    }
}

@media screen and #{$max-width-mobile} {
    .Toastify {
        .Toastify__toast-container {
            width: calc(100vw - 16px);
            margin: snif-grid(1);
        }
    }
}
