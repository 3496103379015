@import 'src/scss/variables';
@import 'src/scss/palette';

.navigation-layout {
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 32px 0 32px;
    height: $header-height;
    background-color: $white;
    z-index: 110;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
    top: 0;
    left: 0;

    @media #{$max-width-mobile} {
        height: $sm-header-height;
        padding: 0 18px 0 0;

        &.mob-search-focus {
            box-shadow: none;
        }
    }

    // &_offset {
    //     top: $smart-banner-height;
    // }
}
